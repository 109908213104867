import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import { TwitterService } from '../services/twitter.service';
import { getUserColor } from '../utils/userColors';

interface TweetWithSentiment {
  tweet_id: string;
  text: string;
  sentiment_score: number;
  sentiment_label: string;
  created_at: string;
  tweet_type: 'original' | 'retweet' | 'reply' | 'quote';
  author_id: string;
  public_metrics?: {
    retweet_count: number;
    reply_count: number;
    like_count: number;
    quote_count: number;
    bookmark_count: number;
    impression_count: number;
  };
}

interface TweetWithMetrics {
  tweet_id: string;
  text: string;
  created_at: string;
  author_id: string;
  public_metrics: {
    retweet_count: number;
    reply_count: number;
    like_count: number;
    quote_count: number;
    bookmark_count: number;
    impression_count: number;
  };
}

interface CloudWord {
  text: string;
  size: number;
  x?: number;
  y?: number;
  color: string;
}

interface TweetVisualizationProps {
  selectedUserId: string | null;
  selectedUsers: string[];
  onActiveUsersChange?: (users: string[]) => void;
  userHandles: { [key: string]: string };
  onDaySelect?: (tweetIds: string[]) => void;
  onTweetsUpdate?: (tweets: { [key: string]: any[] }) => void;
}

export const TweetVisualization: React.FC<TweetVisualizationProps> = ({ 
  selectedUserId, 
  selectedUsers, 
  onActiveUsersChange,
  userHandles,
  onDaySelect,
  onTweetsUpdate
}) => {
  console.debug('TweetVisualization render - selectedUserId:', selectedUserId);
  
  const svgRef = useRef<SVGSVGElement>(null);
  const [tweets, setAllTweets] = useState<{ [key: string]: TweetWithSentiment[] }>({});
  const [focusedUser, setFocusedUser] = useState<string>(selectedUserId || '');
  const [selectedDays, setSelectedDays] = useState<Set<string>>(new Set());
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [isDrillDown, setIsDrillDown] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const originalOrderRef = useRef<{ [key: string]: TweetWithSentiment[] }>({});

  useEffect(() => {
    setFocusedUser(selectedUserId || '');
  }, [selectedUserId]);

  useEffect(() => {
    const fetchTweetsForUsers = async () => {
      const newTweets: { [key: string]: TweetWithSentiment[] } = {};
      try {
        // Fetch sentiment data
        for (const userId of selectedUsers) {
          try {
            const response = await TwitterService.getUserTweetsReport(userId);
            if (response && response.length > 0) {
              newTweets[userId] = response.map((tweet: any) => ({
                tweet_id: tweet.tweet_id,
                text: tweet.text,
                sentiment_score: tweet.sentiment_score || 0,
                sentiment_label: tweet.sentiment_label || 'neutral',
                created_at: tweet.created_at,
                tweet_type: tweet.tweet_type || 'original',
                author_id: userId,
                public_metrics: {
                  retweet_count: 0,
                  reply_count: 0,
                  like_count: 0,
                  quote_count: 0,
                  bookmark_count: 0,
                  impression_count: 0,
                },               
              }));
            }
          } catch (error) {
            console.error(`Error fetching report tweets for user ${userId}:`, error);
          }
        }

        // Fetch engagement data and merge
        const engagementResponse = await TwitterService.getUserTweetsMultiple(selectedUsers) as unknown;
        const engagementData = (engagementResponse as { data: Array<{
          id: string;
          text: string;
          created_at: string;
          author_id: string;
          public_metrics: TweetWithMetrics['public_metrics'];
        }> }).data;

        engagementData.forEach((tweet) => {
          const userId = tweet.author_id;
          if (!newTweets[userId]) newTweets[userId] = [];
          const existingTweet = newTweets[userId].find(t => t.tweet_id === tweet.id);
          const defaultMetrics = {
            retweet_count: 0,
            reply_count: 0,
            like_count: 0,
            quote_count: 0,
            bookmark_count: 0,
            impression_count: 0,
          };
          const metrics = tweet.public_metrics || defaultMetrics;
          if (existingTweet) {
            existingTweet.public_metrics = {
              retweet_count: typeof metrics.retweet_count === 'number' && !isNaN(metrics.retweet_count) ? metrics.retweet_count : 0,
              reply_count: typeof metrics.reply_count === 'number' && !isNaN(metrics.reply_count) ? metrics.reply_count : 0,
              like_count: typeof metrics.like_count === 'number' && !isNaN(metrics.like_count) ? metrics.like_count : 0,
              quote_count: typeof metrics.quote_count === 'number' && !isNaN(metrics.quote_count) ? metrics.quote_count : 0,
              bookmark_count: typeof metrics.bookmark_count === 'number' && !isNaN(metrics.bookmark_count) ? metrics.bookmark_count : 0,
              impression_count: typeof metrics.impression_count === 'number' && !isNaN(metrics.impression_count) ? metrics.impression_count : 0,
            };
          } else {
            newTweets[userId].push({
              tweet_id: tweet.id,
              text: tweet.text,
              created_at: tweet.created_at,
              author_id: tweet.author_id,
              sentiment_score: 0,
              sentiment_label: 'neutral',
              tweet_type: 'original',
              public_metrics: {
                retweet_count: typeof metrics.retweet_count === 'number' && !isNaN(metrics.retweet_count) ? metrics.retweet_count : 0,
                reply_count: typeof metrics.reply_count === 'number' && !isNaN(metrics.reply_count) ? metrics.reply_count : 0,
                like_count: typeof metrics.like_count === 'number' && !isNaN(metrics.like_count) ? metrics.like_count : 0,
                quote_count: typeof metrics.quote_count === 'number' && !isNaN(metrics.quote_count) ? metrics.quote_count : 0,
                bookmark_count: typeof metrics.bookmark_count === 'number' && !isNaN(metrics.bookmark_count) ? metrics.bookmark_count : 0,
                impression_count: typeof metrics.impression_count === 'number' && !isNaN(metrics.impression_count) ? metrics.impression_count : 0,
              }
            });
          }
        });

        // Debug: Log a sample of the merged tweets to verify public_metrics
        Object.keys(newTweets).forEach(userId => {
          newTweets[userId].forEach(tweet => {
            if (typeof tweet.public_metrics!.reply_count !== 'number' || isNaN(tweet.public_metrics!.reply_count)) {
              console.warn('Invalid public_metrics for tweet', tweet.tweet_id, tweet.public_metrics);
            }
          });
        });

        setAllTweets(newTweets);
        setIsDataLoaded(true);
        onTweetsUpdate?.(newTweets);
        const usersWithTweets = Object.keys(newTweets);
        onActiveUsersChange?.(usersWithTweets);
      } catch (error) {
        console.error('Error fetching tweets:', error);
        setIsDataLoaded(true);
      }
    };

    if (selectedUsers.length > 0) {
      setIsDataLoaded(false);
      fetchTweetsForUsers();
    } else {
      setAllTweets({});
      setIsDataLoaded(true);
      onActiveUsersChange?.([]);
    }
  }, [selectedUsers]);

  useEffect(() => {
    originalOrderRef.current = { ...tweets };
  }, [tweets]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Control') setIsCtrlPressed(true);
      if (e.key === 'Enter' && !isDrillDown) {
        e.preventDefault();
        setIsDrillDown(true);
        setSelectedDays(new Set([/* day */]));
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Control') setIsCtrlPressed(false);
    };

    const handleBlur = () => setIsCtrlPressed(false);

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('blur', handleBlur);
    };
  }, [isDrillDown]);

  const clearSelection = () => {
    setSelectedDays(new Set());
    setIsDrillDown(false);
  };

  useEffect(() => {
    if (!svgRef.current || !isDataLoaded) return;
    
    const allTweets = Object.values(tweets).flat();
    if (allTweets.length === 0) return;
    
    const dates = allTweets.map(t => new Date(t.created_at)).filter(d => !isNaN(d.getTime()));
    if (dates.length === 0) return;

    const maxDate = new Date(Math.max(...dates.map(d => d.getTime())));
    const endDate = new Date(maxDate);
    endDate.setDate(endDate.getDate() + 1);

    const startDate = new Date(endDate);
    startDate.setMonth(startDate.getMonth() - 3);
    
    const tweetsByDate = Object.values(tweets).flat()
      .filter(tweet => {
        const tweetDate = new Date(tweet.created_at);
        return tweetDate >= startDate && tweetDate <= endDate;
      })
      .reduce((acc, tweet) => {
        const date = new Date(tweet.created_at).toISOString().split('T')[0];
        if (!acc[date]) {
          acc[date] = { count: 0, sentimentSum: 0, tweets: [] };
        }
        acc[date].count++;
        acc[date].sentimentSum += tweet.sentiment_score || 0;
        acc[date].tweets.push(tweet);
        return acc;
      }, {} as Record<string, { count: number; sentimentSum: number; tweets: TweetWithSentiment[] }>);

    const dateRange = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dateStr = currentDate.toISOString().split('T')[0];
      const dayData = tweetsByDate[dateStr] || { count: 0, sentimentSum: 0, tweets: [] };
      dateRange.push({
        date: dateStr,
        count: dayData.count,
        hasData: dayData.count > 0,
        tweets: dayData.tweets,
        sentimentAvg: dayData.count ? dayData.sentimentSum / dayData.count : 0
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const dailyData = dateRange;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 10, right: 20, bottom: 30, left: 60 };
    const width = svgRef.current.clientWidth - margin.left - margin.right;

    // Calculate dynamic height based on max value
    const maxTweetCount = Math.max(...Object.values(tweetsByDate).map(d => d.count));
    const height = Math.min(Math.max(maxTweetCount * 20, 200), 400); // Dynamic height with min/max bounds

    svg.attr('width', width + margin.left + margin.right)
       .attr('height', height + margin.top + margin.bottom)
       .style('margin-left', '-3px'); // Align y-axis with engagement chart

    if (!d3.select('body').select('#tooltip').size()) {
      d3.select('body')
        .append('div')
        .attr('id', 'tooltip')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('background-color', 'rgba(0, 0, 0, 0.8)')
        .style('color', 'white')
        .style('padding', '8px')
        .style('border-radius', '4px')
        .style('font-size', '12px')
        .style('z-index', '10000')
        .style('pointer-events', 'none');
    }

    const g = svg.append('g')
       .attr('transform', `translate(${margin.left},${margin.top})`);

    // Tweet Bar Chart (Top)
    const xScale = d3.scaleBand()
      .domain(d3.timeDay.range(startDate, endDate).map(d => d.toISOString().split('T')[0]))
      .range([0, width])
      .padding(0.1);

    const yScaleTweet = d3.scaleLinear()
      .domain([0, maxTweetCount])
      .range([height, 0])
      .nice();

    const yAxisTweet = d3.axisLeft(yScaleTweet)
      .ticks(5)
      .tickFormat(d3.format('.0f')); // Whole numbers only

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickFormat(() => '').tickSize(4))
      .call(g => g.select('.domain').style('stroke', '#444').style('stroke-width', 1));

    const xAxis = d3.axisBottom(xScale)
      .tickFormat((d: string) => {
        const date = new Date(d);
        return date.getDate() === 1 ? date.toLocaleString('default', { month: 'short' }) : '';
      })
      .tickValues(xScale.domain().filter(d => new Date(d).getDate() === 1))
      .tickSize(0);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .call(g => g.select('.domain').remove())
      .selectAll('text')
      .style('text-anchor', 'start')
      .attr('dx', '0.8em')
      .attr('dy', '1.15em')
      .style('fill', '#fff')
      .style('font-size', '24px')
      .attr('transform', 'rotate(0)');

    // Add validation before tickValues
    const isValidDate = (d: Date): boolean => d instanceof Date && !isNaN(d.getTime());

    // In the axis code:
    const xAxisWeekNotches = d3.axisBottom(xScale)
      .tickValues(
        isValidDate(startDate) && isValidDate(endDate)
          ? d3.timeWeek.every(1)!.range(startDate as Date, endDate as Date)
              .map(d => d.toISOString().split('T')[0])
          : []
      )
      .tickFormat(() => '')
      .tickSize(4);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxisWeekNotches)
      .call(g => g.select('.domain').remove())
      .selectAll('tick')
      .style('stroke', '#fff');

    const overlapDays = new Map<string, number>();
    Object.values(tweets).flat().forEach(tweet => {
      const day = tweet.created_at.split('T')[0];
      overlapDays.set(day, (overlapDays.get(day) || 0) + 1);
    });

    g.selectAll('.overlap-background')
      .data(Array.from(overlapDays.entries()).filter(([day]) => xScale(day) !== undefined))
      .join('rect')
      .attr('class', 'overlap-background')
      .attr('x', ([day]) => xScale(day) || 0)
      .attr('y', 0)
      .attr('width', xScale.bandwidth())
      .attr('height', height)
      .attr('fill', ([day, count]) => (xScale(day) === 0 || count <= 1) ? 'none' : 'rgba(128, 0, 128, 0.1)')
      .attr('pointer-events', 'none');

    dailyData.forEach((day) => {
      const x = xScale(day.date)!;
      if (day.hasData) {
        const barHeight = height - yScaleTweet(day.count);
        const y = yScaleTweet(day.count);
        const userId = day.tweets[0]?.author_id;
        const userColor = userId ? getUserColor(userId) : '#4f46e5';

        // Transparent rectangle for events.
        g.append('rect')
          .attr('x', x)
          .attr('y', 0)
          .attr('width', xScale.bandwidth())
          .attr('height', height)
          .attr('fill', 'transparent')
          .style('pointer-events', 'all')
          .on('mouseenter', function(event) {
            d3.select(this).transition().duration(50).attr('fill', 'rgba(255,255,255,0.1)');
            const tooltip = d3.select('#tooltip');
            const dayTweets = day.tweets;
            const uniqueUsers = Array.from(new Set(dayTweets.map(t => t.author_id)));
            const userList = uniqueUsers.map(userId => `@${userHandles[userId] || userId}`).join(', ');
            const tweetIds = dayTweets.map(t => t.tweet_id).join(', ');
            tooltip
              .style('visibility', 'visible')
              .style('left', (event.pageX + 10) + 'px')
              .style('top', (event.pageY - 10) + 'px')
              .html(`Date: ${new Date(day.date).toLocaleDateString()}<br>Tweets: ${day.count}<br>Score: ${day.sentimentAvg.toFixed(2)}<br>Users: ${userList}<br>IDs: ${tweetIds}`);
          })
          .on('mouseleave', function() {
            d3.select(this).transition().duration(50).attr('fill', 'transparent');
            d3.select('#tooltip').style('visibility', 'hidden');
          })
          .on('mousemove', function(event) {
            d3.select('#tooltip').style('left', (event.pageX + 10) + 'px').style('top', (event.pageY - 10) + 'px');
          })
          .on('click', function() {
            console.log(`Clicked on date ${day.date}`);
            const tweetIds = day.tweets.map(t => t.tweet_id);
            console.log('Tweet IDs:', tweetIds);
            if (onDaySelect) {
              onDaySelect(tweetIds);
            } else {
              console.warn('onDaySelect is not defined');
            }
          });

        // Colored rectangle for visualization.
        g.append('rect')
          .attr('data-date', day.date)
          .attr('data-user', userId)
          .attr('x', x)
          .attr('y', height)
          .attr('width', xScale.bandwidth())
          .attr('height', 0)
          .attr('fill', userColor)
          .style('opacity', userId === selectedUserId ? 0.75 : 1.0)
          .style('pointer-events', 'none')
          .transition()
          .duration(750)
          .ease(d3.easeLinear)
          .attr('y', y)
          .attr('height', barHeight);
      }
    });

    g.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(() => ''))
      .style('stroke', '#1e293b')
      .style('stroke-opacity', 0.1);

    g.append('g')
      .call(yAxisTweet)
      .style('color', '#fff') // Match engagement chart
      .selectAll('text')
      .style('font-size', '14px'); // Larger font size for readability
  }, [tweets, isCtrlPressed, isDrillDown, isDataLoaded]);

  useEffect(() => {
    if (!tweets[focusedUser]) return;

    const colors = ['#34D399', '#60A5FA', '#F87171', '#FBBF24', '#A78BFA', '#EC4899'];
    const wordMap = new Map<string, number>();
    tweets[focusedUser].forEach(tweet => {
      tweet.text.toLowerCase()
        .split(/\s+/)
        .filter(w => w.length > 3 && !w.startsWith('http'))
        .forEach(word => wordMap.set(word, (wordMap.get(word) || 0) + 1));
    });

    const words: CloudWord[] = Array.from(wordMap.entries())
      .map(([text, freq], i) => ({ 
        text, 
        size: Math.sqrt(freq) * 10,
        color: colors[i % colors.length],
        x: 0,
        y: 0
      }))
      .sort((a, b) => b.size - a.size)
      .slice(0, 50);

    d3.select("#wordcloud").selectAll("*").remove();
    cloud<CloudWord>()
      .size([500, 400])
      .words(words)
      .padding(5)
      .rotate(() => 0)
      .fontSize(d => d.size)
      .on("end", draw)
      .start();

    function draw(words: CloudWord[]) {
      d3.select("#wordcloud")
        .append("g")
        .attr("transform", "translate(250,200)")
        .selectAll("text")
        .data(words)
        .join("text")
        .style("font-size", d => `${d.size}px`)
        .style("fill", d => d.color)
        .attr("text-anchor", "middle")
        .attr("transform", d => `translate(${d.x},${d.y})`)
        .text(d => d.text);
    }
  }, [tweets, focusedUser]);

  const renderLegend = (isDetailView: boolean) => (
    <div className="flex items-center gap-4">
      {isDetailView ? (
        <>
          <div className="flex items-center gap-2"><div className="w-3 h-3 rounded-full bg-blue-500"></div><span className="text-white text-sm">Original</span></div>
          <div className="flex items-center gap-2"><div className="w-3 h-3 rounded-full bg-green-500"></div><span className="text-white text-sm">Retweet</span></div>
          <div className="flex items-center gap-2"><div className="w-3 h-3 rounded-full bg-red-500"></div><span className="text-white text-sm">Reply</span></div>
          <div className="flex items-center gap-2"><div className="w-3 h-3 rounded-full bg-yellow-500"></div><span className="text-white text-sm">Quote</span></div>
        </>
      ) : (
        <>
          <div className="flex items-center gap-2"><div className="w-3 h-3 rounded-full bg-gray-500"></div><span className="text-white text-sm">Daily Average</span></div>
          <div className="flex items-center gap-2"><div className="w-3 h-3 bg-blue-500 opacity-30"></div><span className="text-white text-sm">Ctrl+Hover to Select</span></div>
          <div className="flex items-center gap-2"><div className="w-3 h-3 bg-blue-500"></div><span className="text-white text-sm">Enter to Drill Down</span></div>
        </>
      )}
    </div>
  );

  const activeUsers = Array.from(new Set(Object.values(tweets).flat().map(tweet => tweet.author_id))).filter(Boolean);
  useEffect(() => {
    if (activeUsers.length > 0) onActiveUsersChange?.(activeUsers);
  }, [tweets]);

  return (
    <div className="relative w-full bg-black border border-gray-700 rounded-lg p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <h2 className="text-white text-lg">
            {isDrillDown ? `Tweets for ${new Date(Array.from(selectedDays)[0]).toLocaleDateString()}` : 'Daily Tweet Summary'}
          </h2>
          {renderLegend(isDrillDown)}
          {(selectedDays.size > 0 || isDrillDown) && (
            <button onClick={clearSelection} className="px-3 py-1 text-sm bg-gray-800 text-white rounded hover:bg-gray-700">Back to Summary</button>
          )}
        </div>
        {selectedUsers.length > 0 && (
          <select
            value={focusedUser}
            onChange={(e) => setFocusedUser(e.target.value)}
            className="min-w-[200px] bg-gray-800 text-white border border-gray-600 rounded-md px-4 py-2 text-sm"
          >
            {selectedUsers.map(user => <option key={user} value={user}>@{user}</option>)}
          </select>
        )}
      </div>
      <svg ref={svgRef} className="w-full bg-black rounded-md"></svg> {/* Removed fixed height */}
    </div>
  );
};