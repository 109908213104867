
// Pre-defined colors for known users
const KNOWN_USER_COLORS: { [key: string]: string } = {
  '20559705': '#FF6B6B',   // red
  '227531567': '#4ECDC4',  // teal
  '22887941': '#45B7D1',   // blue
  '254515782': '#96CEB4',  // green
  '274249799': '#FFEEAD',  // yellow
};

// Store dynamically generated colors
const dynamicUserColors: { [key: string]: string } = {};

// Generate a deterministic color based on userId
const generateUserColor = (userId: string): string => {
  // Use hash function to generate a number from userId
  const hash = userId.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  
  // Use the hash to pick a hue value (0-360)
  const hue = Math.abs(hash % 360);
  
  // Create HSL color with fixed saturation and lightness
  return `hsl(${hue}, 70%, 60%)`;
};

// Get or generate a color for a user
export const getUserColor = (userId: string): string => {
  // Return pre-defined color if exists
  if (KNOWN_USER_COLORS[userId]) {
    return KNOWN_USER_COLORS[userId];
  }
  
  // Return previously generated color if exists
  if (dynamicUserColors[userId]) {
    return dynamicUserColors[userId];
  }
  
  // Generate new color
  const newColor = generateUserColor(userId);
  dynamicUserColors[userId] = newColor;
  return newColor;
};

// Export known colors for direct access
export const KNOWN_COLORS = KNOWN_USER_COLORS; 