import { useEffect, useState, useRef } from 'react';
import { TwitterService } from '../services/twitter.service';

interface TwitterUser {
  id: string;
  name: string;
  username: string;
}

interface TwitterConnectProps {
  selectedUsers: string[];
  onSelectedUsersChange: (users: string[]) => void;
  onUserHandlesChange: (handles: { [key: string]: string }) => void;
}

export function TwitterConnect({ selectedUsers, onSelectedUsersChange }: TwitterConnectProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<TwitterUser[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mode, setMode] = useState<'user' | 'group'>('user');
  const [groups, setGroups] = useState<{ [key: string]: string[] }>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadData = async () => {
      const [usersResponse, groupsResponse] = await Promise.all([
        TwitterService.getKnownUsers(),
        TwitterService.getKnownGroups()
      ]);
      setUsers(usersResponse.users);
      setGroups(groupsResponse.groups);
    };
    loadData();

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleUserSelect = (userId: string) => {
    onSelectedUsersChange(
      selectedUsers.includes(userId) 
        ? selectedUsers.filter(id => id !== userId)
        : [...selectedUsers, userId]
    );
  };

  const handleGroupSelect = (groupName: string) => {
    const groupUsers = groups[groupName] || [];
    onSelectedUsersChange(groupUsers);
    setSearchTerm('');
  };

  const filteredUsers = users.filter(user => 
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full relative">
        <div className="flex items-center gap-4 mb-4">
          <label className="flex items-center text-white">
            <input
              type="radio"
              value="user"
              checked={mode === 'user'}
              onChange={() => setMode('user')}
              className="mr-2"
            />
            User Mode:
          </label>
          <label className="flex items-center text-white">
            <input
              type="radio"
              value="group"
              checked={mode === 'group'}
              onChange={() => setMode('group')}
              className="mr-2"
            />
            Group Mode:
          </label>
        </div>

        <div className="flex items-center gap-2">
          <div className="flex-1 relative" ref={dropdownRef}>
            {mode === 'user' ? (
              <>
                <input
                  type="search"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowDropdown(true);
                  }}
                  onFocus={() => setShowDropdown(true)}
                  placeholder="Search users..."
                  className="block w-full p-2.5 ps-10 text-sm text-white rounded-lg border bg-gray-700 border-gray-600 placeholder-gray-400 focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                />
                {showDropdown && searchTerm && filteredUsers.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-700 rounded-lg shadow-lg">
                    {filteredUsers.map(user => (
                      <div
                        key={user.id}
                        onClick={() => handleUserSelect(user.id)}
                        className={`flex items-center p-3 cursor-pointer hover:bg-gray-700 ${
                          selectedUsers.includes(user.id) ? 'bg-gray-700' : ''
                        }`}
                      >
                        <div className="flex-1">
                          <div className="text-white">{user.name}</div>
                          <div className="text-gray-400">@{user.username}</div>
                        </div>
                        {selectedUsers.includes(user.id) && (
                          <span className="text-blue-500">✓</span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <select
                onChange={(e) => handleGroupSelect(e.target.value)}
                className="block w-full p-2.5 text-sm text-white rounded-lg border bg-gray-700 border-gray-600 focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
              >
                <option value="">Select a Group</option>
                {Object.keys(groups).map(groupName => (
                  <option key={groupName} value={groupName}>{groupName}</option>
                ))}
              </select>
            )}
          </div>
          {selectedUsers.length > 0 && (
            <button
              onClick={() => onSelectedUsersChange(selectedUsers)}
              className="px-6 py-2.5 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors whitespace-nowrap"
            >
              Search Tweets
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
