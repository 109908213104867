import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { getUserColor } from '../utils/userColors';

interface TweetWithSentiment {
  tweet_id: string;
  text: string;
  sentiment_score: number;
  sentiment_label: string;
  created_at: string;
  tweet_type: 'original' | 'retweet' | 'reply' | 'quote';
  author_id: string;
}

interface ArcDiagramProps {
  tweets: { [key: string]: TweetWithSentiment[] };
  userHandles: { [key: string]: string };
  partyUserIds: string[];
  selectedTweets?: { [key: string]: TweetWithSentiment[] };
}

interface Node {
  id: string;
  handle: string;
}

interface Link {
  source: string;
  target: string;
  interactionCount: number;
  avgSentiment: number;
  interactions: { tweetId: string; text: string; sentiment: number; type: 'original' | 'retweet' | 'reply' | 'quote' }[];
}

export const ArcDiagram: React.FC<ArcDiagramProps> = ({
  tweets,
  userHandles,
  partyUserIds,
  selectedTweets,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [filteredUserId, setFilteredUserId] = useState<string | null>(null);

  useEffect(() => {
    console.log('ArcDiagram useEffect triggered');
    if (!svgRef.current || !partyUserIds.length || !Object.keys(tweets).length) return;

    const allTweets = Object.values(tweets).flat();
    if (allTweets.length === 0) return;

    console.log('userHandles:', userHandles);
    console.log('partyUserIds:', partyUserIds);
    console.log('allTweets:', allTweets);

    // Step 1: Extract nodes (all party users involved in tweets or mentions)
    const nodesSet = new Set<string>();
    allTweets.forEach(tweet => {
      if (partyUserIds.includes(tweet.author_id)) {
        nodesSet.add(tweet.author_id);
      }

      const mentions: string[] = tweet.text.match(/@(\w+)/g) || [];
      mentions.forEach((mention: string) => {
        const handle = mention.slice(1);
        const userId = Object.keys(userHandles).find(id => userHandles[id].toLowerCase() === handle.toLowerCase());
        if (userId && partyUserIds.includes(userId)) {
          nodesSet.add(userId);
        }
      });
    });

    const nodes: Node[] = Array.from(nodesSet).map(id => ({
      id,
      handle: userHandles[id] || id,
    }));

    console.log('Nodes:', nodes);

    // Step 2: Create links (all interactions involving party users)
    const linksMap = new Map<string, Link>();
    allTweets.forEach(tweet => {
      const mentions: string[] = tweet.text.match(/@(\w+)/g) || [];
      const mentionedPartyUsers: string[] = [];

      mentions.forEach((mention: string) => {
        const handle = mention.slice(1);
        const userId = Object.keys(userHandles).find(id => userHandles[id].toLowerCase() === handle.toLowerCase());
        if (userId && partyUserIds.includes(userId)) {
          mentionedPartyUsers.push(userId);
        }
      });

      if (partyUserIds.includes(tweet.author_id)) {
        const sourceId = tweet.author_id;
        mentionedPartyUsers.forEach(targetId => {
          if (nodesSet.has(targetId)) {
            const key = `${sourceId}-${targetId}`;
            const link = linksMap.get(key) || {
              source: sourceId,
              target: targetId,
              interactionCount: 0,
              avgSentiment: 0,
              interactions: [],
            };
            link.interactions.push({
              tweetId: tweet.tweet_id,
              text: tweet.text,
              sentiment: isNaN(tweet.sentiment_score) ? 0 : tweet.sentiment_score,
              type: tweet.tweet_type,
            });
            link.interactionCount += 1;
            const sentimentSum = link.interactions.reduce((sum, i) => sum + i.sentiment, 0);
            link.avgSentiment = link.interactions.length > 0 ? sentimentSum / link.interactions.length : 0;
            linksMap.set(key, link);
          }
        });
      }

      for (let i = 0; i < mentionedPartyUsers.length; i++) {
        for (let j = i + 1; j < mentionedPartyUsers.length; j++) {
          const sourceId = mentionedPartyUsers[i];
          const targetId = mentionedPartyUsers[j];
          if (nodesSet.has(sourceId) && nodesSet.has(targetId)) {
            const key = `${sourceId}-${targetId}`;
            const link = linksMap.get(key) || {
              source: sourceId,
              target: targetId,
              interactionCount: 0,
              avgSentiment: 0,
              interactions: [],
            };
            link.interactions.push({
              tweetId: tweet.tweet_id,
              text: tweet.text,
              sentiment: isNaN(tweet.sentiment_score) ? 0 : tweet.sentiment_score,
              type: tweet.tweet_type,
            });
            link.interactionCount += 1;
            const sentimentSum = link.interactions.reduce((sum, i) => sum + i.sentiment, 0);
            link.avgSentiment = link.interactions.length > 0 ? sentimentSum / link.interactions.length : 0;
            linksMap.set(key, link);
          }
        }
      }
    });

    const links: Link[] = Array.from(linksMap.values());
    console.log('Links:', links);

    const filteredLinks = filteredUserId
      ? links.filter(link => link.source === filteredUserId || link.target === filteredUserId)
      : links;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 40, right: 20, bottom: 20, left: 20 };
    const SVG_HEIGHT = 260;
    const height = 200;
    const width = svgRef.current.clientWidth - margin.left - margin.right;
    const midlineY = height / 2; // Drawing coordinates: halfway up the drawing area

    svg.attr('width', width + margin.left + margin.right)
       .attr('height', SVG_HEIGHT);

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scalePoint()
      .domain(nodes.map(d => d.id))
      .range([0, width])
      .padding(0.5);

    const maxInteractionCount = d3.max(filteredLinks, d => d.interactionCount) || 1;
    const linkThickness = d3.scaleLinear()
      .domain([1, maxInteractionCount])
      .range([1, 5]);

    const tweetTypeColor = d3.scaleOrdinal<string>()
      .domain(['original', 'retweet', 'reply', 'quote'])
      .range(['#34D399', '#F87171', '#60A5FA', '#FBBF24']);

    filteredLinks.forEach((link, index) => {
      const sourceX = xScale(link.source);
      const targetX = xScale(link.target);
      console.log(`Link: ${link.source} -> ${link.target}, sourceX: ${sourceX}, targetX: ${targetX}`);

      if (sourceX === undefined || targetX === undefined || sourceX === targetX) {
        console.warn(`Skipping arc rendering for link ${link.source} -> ${link.target}: Invalid x-coordinates`);
        return;
      }

      const radius = Math.abs(targetX - sourceX) * 0.75;
      // Alternate direction: odd index goes below (sweep-flag = 0), even index goes above (sweep-flag = 1).
      const sweepFlag = index % 2 === 0 ? 1 : 0;
      const pathData = `
        M ${sourceX} ${midlineY}
        A ${radius} ${radius} 0 0 ${sweepFlag} ${targetX} ${midlineY}
      `;

      const arcPath = g.append('path')
        .attr('class', 'arc-link')
        .attr('d', pathData)
        .attr('fill', 'none')
        .attr('stroke', tweetTypeColor(link.interactions[0].type))
        .attr('stroke-width', linkThickness(link.interactionCount))
        .attr('opacity', 0.7);

      const totalLength = arcPath.node()!.getTotalLength();
      arcPath
        .attr('stroke-dasharray', totalLength + ' ' + totalLength)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(750)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);

      arcPath
        .on('mouseover', function(event) {
          d3.select(this).attr('opacity', 1);
          const tooltip = d3.select('#arc-tooltip');
          tooltip
            .style('visibility', 'visible')
            .style('left', (event.pageX + 10) + 'px')
            .style('top', (event.pageY - 10) + 'px')
            .html(`
              <strong>Interaction:</strong> ${userHandles[link.source]} ↔ ${userHandles[link.target]}<br>
              <strong>Interactions:</strong> ${link.interactionCount}<br>
              <strong>Tweet Type:</strong> ${link.interactions[0].type}<br>
              <strong>Sample:</strong> ${link.interactions[0].text.substring(0, 50)}...
            `);
        })
        .on('mouseout', function() {
          d3.select(this).attr('opacity', 0.7);
          d3.select('#arc-tooltip').style('visibility', 'hidden');
        });
    });

    const nodeGroup = g.selectAll('.node')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${xScale(d.id)},${midlineY})`); // Move nodes to midline

    nodeGroup.append('circle')
      .attr('r', 5)
      .attr('fill', d => getUserColor(d.id))
      .attr('stroke', '#fff')
      .attr('stroke-width', 1)
      .on('mouseover', function(_event, d) {
        d3.select(this).attr('r', 8);
        g.selectAll('path.arc-link')
          .attr('opacity', 0.2)
          .filter(function(datum: any) {
            if (!datum) return false;
            const link = datum as Link;
            return link.source === d.id || link.target === d.id;
          })
          .attr('opacity', 1);
      })
      .on('mouseout', function() {
        d3.select(this).attr('r', 5);
        g.selectAll('path.arc-link').attr('opacity', 0.7);
      })
      .on('click', (_event, d) => {
        setFilteredUserId(d.id === filteredUserId ? null : d.id);
      });

    nodeGroup.append('text')
      .attr('dy', 15)
      .attr('dx', 0)
      .attr('text-anchor', 'middle')
      .style('fill', '#fff')
      .style('font-size', '12px')
      .text(d => `@${d.handle}`);

    const xAxis = d3.axisBottom(xScale)
      .tickFormat(() => '')
      .tickSize(0);

    g.append('g')
      .attr('transform', `translate(0,${midlineY})`) // Move axis to midline
      .call(xAxis)
      .call(g => g.select('.domain').style('stroke', '#444').style('stroke-width', 1));

    if (!d3.select('body').select('#arc-tooltip').size()) {
      d3.select('body')
        .append('div')
        .attr('id', 'arc-tooltip')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('background-color', 'rgba(0, 0, 0, 0.8)')
        .style('color', 'white')
        .style('padding', '8px')
        .style('border-radius', '4px')
        .style('font-size', '12px')
        .style('z-index', '10000')
        .style('pointer-events', 'none');
    }
  }, [tweets, userHandles, partyUserIds, selectedTweets, filteredUserId]);

  return (
    <div className="w-full bg-black border border-gray-700 rounded-lg p-4 mt-4">
      <h2 className="text-white text-lg mb-2">User Interaction Arc Diagram</h2>
      <svg ref={svgRef} className="w-full bg-black rounded-md"></svg>
    </div>
  );
};
