import { getUserColor } from '../utils/userColors';

interface LegendControlProps {
  type: 'users' | 'metrics';
  users?: { id: string; handle: string; selected?: boolean }[];
  userHandles?: { [key: string]: string };
}

export const LegendControl: React.FC<LegendControlProps> = ({ type, users, userHandles }) => {
  if (type === 'users' && users && userHandles) {
    return (
      <div className="flex flex-wrap gap-4 p-2 bg-black border border-gray-700 rounded-lg">
        {users.map((user, _) => (
          <div key={user.id} className="flex items-center gap-2">
            <div
              className="w-4 h-4 rounded-sm flex-shrink-0"
              style={{ backgroundColor: getUserColor(user.id), opacity: user.selected ? 0.75 : 1.0 }}
            />
            <span className="text-white text-sm">{`@${user.handle}`}</span>
          </div>
        ))}
      </div>
    );
  }

  if (type === 'metrics') {
    const metrics = [
      { name: 'Likes', color: '#ff9500' },
      { name: 'Retweets', color: '#34D399' },
      { name: 'Replies', color: '#60A5FA' }
    ];
    return (
      <div className="flex flex-wrap gap-4 p-2 bg-black border border-gray-700 rounded-lg">
        {metrics.map((metric, _) => (
          <div key={metric.name} className="flex items-center gap-2">
            <div
              className="w-4 h-4 rounded-sm flex-shrink-0"
              style={{ backgroundColor: metric.color }}
            />
            <span className="text-white text-sm">{metric.name}</span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};