import type { TwitterResponse } from '@retentio/api-interfaces';
const API_BASE_URL = `${import.meta.env.VITE_DOMAIN.replace(/\/$/, '')}/api`;

// Add mock data at the top of the file


// Add a flag to control mocking
//const USE_MOCKS = import.meta.env.DEV && import.meta.env.VITE_USE_MOCK_TWEETS === 'true';

export class TwitterService {
  private static readonly API_BASE_URL = import.meta.env.VITE_API_PREFIX 
    ? `${import.meta.env.VITE_USE_MOCK_TWEETS.replace(/\/$/, '')}${import.meta.env.VITE_API_PREFIX}`
    : import.meta.env.VITE_DOMAIN.replace(/\/$/, '');

  static async getUserTweets(userId: string): Promise<TwitterResponse> {
    // First get tweets with sentiment analysis
    const response = await fetch(`${this.API_BASE_URL}/tweet/users/${userId}/tweets`);
    if (!response.ok) throw new Error('Failed to getUserTweets');
    return response.json();
  }
  static async getUserTweetsReport(userId: string): Promise<TwitterResponse> {
    //if (USE_MOCKS) return MOCK_TWEETS;
    console.debug('getUserTweetsReport');
    const response = await fetch(`https://retent.io/analyze/user/${userId}/batch`);
    if (!response.ok) throw new Error('Failed to fetch tweets');
    
    return response.json();
  }
  static async initiateAuth(): Promise<string> {
    const response = await fetch(`${API_BASE_URL}/tweet/setup`);
    const data = await response.json();
    return data.authUrl; // assuming your endpoint returns an auth URL
  }

  static async postTweet(content: string): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/tweet/tweet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content }),
    });

    if (!response.ok) {
      throw new Error('Failed to post tweet');
    }
  }

  static async getKnownUsers() {
    try {
      const response = await fetch(`${this.API_BASE_URL}/tweet/known-users`);
      if (!response.ok) {
        throw new Error('Failed to fetch known users');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching known users:', error);
      return { users: [] };
    }
  }

  static async getUserTweetsMultiple(userIds: string[]): Promise<TwitterResponse> {
    const response = await fetch(`${this.API_BASE_URL}/tweet/users/tweets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userIds })
    });
    
    if (!response.ok) throw new Error('Failed to fetch tweets');
    return response.json();
  }

  static async getKnownGroups() {
    try {
        const response = await fetch(`${this.API_BASE_URL}/tweet/known-groups`);
        if (!response.ok) {
            throw new Error('Failed to fetch known groups');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching known groups:', error);
        return { groups: {} };
    }
  }
}